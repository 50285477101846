<template>
    <div>
        <!-- <span>Log Integrations {{ (getTitle) ? `- ${getTitle}` : '' }}</span> -->
        <SubHeader :type="'Log Integrations'" />
        <div class="container-xl py-2 mx-auto w-full my-4 flex flex-col">
            <div class="bg-gray-200 md:rounded-lg my-5 py-3 md:py-0">
            <div class="form-control px-4 py-2 md:py-5">
                    <label class="label pt-0">
                        <span class="label-text">Keyword</span>
                    </label>
                    <input type="text" placeholder="ID, Proyecto, Canal, Servicio..." class="dl-input max-w-md bg-white" v-model="filterKeyword" v-debounce:700ms="search">
                    <button class="btn btn-error btn-xs w-max self-center mt-6" style="height: fit-content" v-if="filterKeyword" @click="cleanFilter()">Borrar filtro</button>
                </div>
            </div>
            <div class="w-full mb-20">
                <div class="mx-auto">
                    <div class="flex flex-col">
                        <div class="w-full flex justify-center items-center">
                            <Spinner class="mt-8" color='blue' v-if="$store.state.AdminLogIntegration.loading" />
                        </div>
                        <div class="overflow-x-auto" v-if="!$store.state.AdminLogIntegration.loading">
                            <PaginationSelect
                                :current_page="$store.state.AdminLogIntegration.meta.current_page"
                                :from="$store.state.AdminLogIntegration.meta.from"
                                :last_page="$store.state.AdminLogIntegration.meta.last_page"
                                :path="$store.state.AdminLogIntegration.meta.path"
                                :per_page="$store.state.AdminLogIntegration.meta.per_page"
                                :to="$store.state.AdminLogIntegration.meta.to"
                                :total="$store.state.AdminLogIntegration.meta.total"
                                @change-page="paginationChange"
                            />
                            <div class="py-2 align-middle inline-block min-w-full" v-if="$store.state.AdminLogIntegration.data.length">
                                <div class="overflow-hidden border border-gray-200 sm:rounded-lg">
                                    <table class="min-w-full divide-y divide-gray-200 table-fixed">
                                        <thead class="bg-gray-50">
                                            <tr>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">#ID</th>
                                                <th colspan="25%" scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">Message</th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">Date init</th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">Date end</th>
                                                <th scope="col" class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">Created</th>
                                            </tr>
                                        </thead>
                                        <tbody class="bg-white divide-y divide-gray-200">
                                            <tr v-for="(log, logIndex) in $store.state.AdminLogIntegrations.data" :key="logIndex" class="transition hover:bg-gray-100">
                                                <td class="px-5">
                                                    <span class="px-3 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-900">
                                                        #{{ log.id }}
                                                    </span>
                                                </td>
                                                <td class="px-5" colspan="25%">
                                                    <div class="text-sm text-gray-700 font-semibold inline-block break-all">{{ log.message }}</div>
                                                </td>
                                                <td class="px-5">
                                                    <div class="text-sm text-gray-700 font-semibold inline-block">{{ $moment(log.date_init).format('DD/MM/YYYY HH:mm') }}</div>
                                                </td>
                                                <td class="px-5">
                                                    <div class="text-sm text-gray-700 font-semibold inline-block">{{ $moment(log.date_end).format('DD/MM/YYYY HH:mm') }}</div>
                                                </td>
                                                <td class="px-5 text-left text-sm text-gray-500">{{ $moment(log.created_at).fromNow(true) }}<br><small class="text-xs font-semibold text-gray-600">{{ $moment(log.created_at).format('DD/MM/YYYY HH:mm') }}</small></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div v-else>
                                <div class="flex flex-col items-center mt-20">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-32 text-gray-300">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                    </svg>
                                    <span class="px-6 py-3 text-left text-xs font-semibold text-gray-900 uppercase tracking-wider">{{ $t("adminProjects.notfound") }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import WrapperFilter from "@/components/WrapperFilter";
import PaginationSelect from "@/components/PaginationSelect";
import Spinner from "@/components/UI/Spinner";
import SubHeader from "@/components/SubHeader"

export default {
    name: 'LogIntegration',
    components: {
        SubHeader,
        // WrapperFilter,
        PaginationSelect,
        Spinner
    },
    computed: {
        getTitle(){
            return this.$store.state.AdminLogIntegration.title
        }
    },
    data(){
        return {
            default: {
                limit: 50
            },
            filterKeyword: "",
            title: '',
        }
    },
    methods: {
        async search(config = {}) {
            let filter = {}

            if (this.filterKeyword) filter["filter[id|message|integration_id|date_init|date_end|created_at][like]"] = `%${this.filterKeyword}%`

            filter.page  = config.page || 1;
            filter.limit = config.limit || this.default.limit;
            filter.sort = '-created_at';
            filter.include = 'integration.channel,integration.project';

            await this.$store.dispatch('AdminLogIntegration/list', {filter, integration_id: this.$route.params.integration_id});

        },

        paginationChange: function (page) {
            this.search({page})
        },

        cleanFilter(){
            this.filterKeyword = "";
            this.search();
        }
    },
    async created(){
        await this.search();
    }
}
</script>